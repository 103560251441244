/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { useAuth } from '../../hooks/useAuth';
import {
  listParts,
  updatePartPrice,
  listPartsSellerCompany,
  updateCostCode,
} from '../../store/actions/parts';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import CarouselItem from '../ui/Carousel/CarouselItem';
import styles from './styles';
import {
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridFilteredSortedRowEntriesSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { fromGridRowsToObjectArray } from '../../utils/csvParser';
import Carousel from 'react-material-ui-carousel';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { renderCell } from '../ui/Datagrid/table_config';
import { USER_TYPE } from '../../utils/constants';
import { useTheme } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import ScrollToTopButton from '../ui/ScrollToTopButton';
import { stringifyDeepEqual } from '../../utils/objectHelpers';
import { updateUserPreferences } from '../../store/actions/authorization';

const Part = () => {
  const productsToSet = 21;
  const classes = styles();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const [searchParams] = useSearchParams();
  const category = searchParams.get('cat');
  const [carrouselDataSource, setCarrouselDataSource] = useState([]);
  const [gridFilters, setGridFrilters] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [productsPerPage, setProductsPerPage] = useState(productsToSet);
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const gridApiRef = useGridApiRef();

  const [viewApiRef, setViewApiRef] = useState(apiRef);

  useEffect(() => {
    goToTop();
  }, [category]);

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (bottom && !loadingMoreProducts && productsPerPage < parts.data.length) {
      setLoadingMoreProducts(true);
      setProductsPerPage(prev => {
        if (prev + productsToSet > parts.data.length) {
          return parts.data.length;
        }
        return prev + productsToSet;
      });
      setTimeout(() => {
        setLoadingMoreProducts(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (user.marketPlaceInventoryCarouselView) {
      window.addEventListener('scroll', handleScroll, {
        passive: true
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [user.marketPlaceInventoryCarouselView, parts.data.length]);

  const partsMounted = React.useRef(null);
  const storedShoppingCartsData = JSON.parse(localStorage.getItem('shoppingCarts'));

  useEffect(() => {
    let getPreferences;
    if (parts.loading && storedShoppingCartsData) {
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GREEN_LIST,
        payload: storedShoppingCartsData?.orders || [],
      });
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GRAY_LIST,
        payload: storedShoppingCartsData?.quotes || [],
      });
      return;
    }
    if (!parts.loading && (!stringifyDeepEqual(storedShoppingCartsData, user.preferences.shoppingCarts))) {
      getPreferences = setTimeout(() => {
        localStorage.setItem('shoppingCarts', JSON.stringify(user.preferences.shoppingCarts));
        if (partsMounted) {
          dispatch(updateUserPreferences());
        }
        partsMounted.current = true;
      }, 500);
    }
    return () => {
      clearTimeout(getPreferences);
      partsMounted.current = false;
    };
  }, [user.preferences.shoppingCarts, parts.loading]);

  useEffect(() => {
    if (!parts.loading) {
      const companyId = searchParams.get('companyId');
      const vendorId = searchParams.get('vendorId');
      const customerName = searchParams.get('name');
      const customerRigUserId = searchParams.get('customerRigUser');
      if (
        (user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN || user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || user.type === USER_TYPE.CUSTOMER_USER || user.type === USER_TYPE.CUSTOMER_MANAGER || user.type === USER_TYPE.CUSTOMER_ADMIN || user.type === USER_TYPE.CUSTOMER_RIG) &&
        companyId &&
        vendorId
      ) {
        dispatch(listPartsSellerCompany(companyId, vendorId));
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: customerName,
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.MENU_SET_TITLE,
          payload: customerName,
        });
        if (customerRigUserId) {
          dispatch(listParts(customerRigUserId));
        } else {
          dispatch(listParts());
        }
      }
    }

    dispatch({
      type: REDUX_ACTIONS.SET_MARKETPLACE_INVENTORY_VIEW,
      payload: { marketPlaceInventoryCarouselView: true },
    });
  }, []);

  useEffect(() => {
    const apiRefToUse = user.marketPlaceInventoryCarouselView ? gridApiRef : apiRef;
    if (apiRefToUse.current && category && !parts.searchTerm) {
      apiRefToUse.current.setFilterModel({
        items: [
          {
            id: 'customfilter',
            columnField: 'Category',
            operatorValue: 'equals',
            value: category,
          },
        ],
      });
      setViewApiRef(apiRefToUse);
    } else {
      apiRefToUse.current.setFilterModel({
        items: [],
      });
      setViewApiRef(apiRefToUse);
    }
  }, [user.marketPlaceInventoryCarouselView, category, !parts.searchTerm]);

  useEffect(() => {
    if (!parts.loading) {
      const newCategories = parts.data.map(item => item.Category).filter((value, index, self) => self.indexOf(value) === index);
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_LOADING,
      });
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_GET_SUCCESS,
        payload: newCategories.sort(),
      });
    }
  }, [parts.data]);

  useEffect(() => {
    if (parts.data.length > 0) {
      const apiRefToUse = user.marketPlaceInventoryCarouselView ? gridApiRef : apiRef;
      const filter = parts.searchTerm
        ? []
        : [
            {
              id: 'customfilter',
              columnField: 'Category',
              operatorValue: 'equals',
              value: category,
            },
          ];
      if (apiRefToUse.current) {
        apiRefToUse.current.setPage(0);
        apiRefToUse.current.setFilterModel({
          items: filter
        });
      }
    }
  }, [parts.data.length, category, parts.searchTerm]);

  useEffect(() => {
    const apiRefToUse = user.marketPlaceInventoryCarouselView ? gridApiRef : apiRef;
    const csv = apiRefToUse.current.getDataAsCsv({
      allColumns: true,
      getRowsToExport: () =>
        gridPaginatedVisibleSortedGridRowIdsSelector(apiRefToUse),
    });
    const datasource = fromGridRowsToObjectArray(csv);
    buildCarouselDataSource(datasource);
  }, [gridFilters, parts.data, user.marketPlaceInventoryCarouselView, productsPerPage]);

  const buildCarouselDataSource = (data) => {
    // get only what we need;
    const dataSubset = data;
    // parts.data.slice(
    //   pageSize * currentPage,
    //   pageSize * currentPage + pageSize
    // );

    //  return if no data;
    if (dataSubset.length === 0) {
      setCarrouselDataSource([]);
      setSelectedRowId(null);
      return;
    }
    // get data source for carousel;
    const carrouselDisplayedItemsQty = user.marketPlaceInventoryCarouselView ? productsPerPage : 3;
    const datasource = [];
    for (
      let index = 0;
      index < dataSubset.length / carrouselDisplayedItemsQty;
      index++
    ) {
      const dataSourceItem = [];
      dataSubset.forEach((element, i) => {
        if (
          index * carrouselDisplayedItemsQty <= i &&
            i < index * carrouselDisplayedItemsQty + carrouselDisplayedItemsQty
        ) {
          dataSourceItem.push(element);
        }
      });
      datasource.push(dataSourceItem);
    }
    setCarrouselDataSource(datasource);
  };

  const handleClassificationChange = async (itemId, newClassification) => {
    const apiRefToUse = user.marketPlaceInventoryCarouselView ? gridApiRef : apiRef;
    const classifyParts = apiRefToUse.current.getRow(itemId);
    classifyParts.Classification = newClassification.toString();
    apiRefToUse.current.updateRows([classifyParts]);
  };

  const onStateChange = (newState) => {
    setGridFrilters((_state) => ({ ...newState }));
  };

  const updatePrice = (params) => {
    const part = apiRef.current.getRow(params.id);
    part.Price = params.value.toString();

    const companyId = searchParams.get('companyId') ? searchParams.get('companyId') : user.companyId;

    dispatch(updatePartPrice(params.id, part, companyId));
  };

  const handleUpdateCostCode = (params) => {
    const companyId = searchParams.get('companyId') ? searchParams.get('companyId') : user.companyId;

    dispatch(updateCostCode(params?.id, params?.value, companyId));
  };

  const goToTop = () => {
    const anchor = document.querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'instant'
      });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sx={{ mb: theme.spacing(3), marginTop: user.marketPlaceInventoryCarouselView ? '80px' : '5px' }}>
      <Grid id="back-to-top-anchor" item xs={12} sx={{ '.MuiPaper-root': { padding: '0px', height: '10px' } }} visibility={!user.marketPlaceInventoryCarouselView ? 'hidden' : 'none'}>
        <Datagrid
          sx= {{
            '.MuiDataGrid-main': { visibility: 'hidden', height: '0px' },
            '.MuiDataGrid-footerContainer': { visibility: 'hidden', height: '0px' },
            border: 'none',
            position: 'fixed',
            zIndex: 1000,
            marginTop: '-1px'
          }}
          hideFooter
          height={'5vh'}
          loading={parts.loading}
          data={parts.data}
          columns={
            columns(renderCell, user.type)
          }
          apiRef={gridApiRef}
          onFilterModelChange={() => {
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(gridApiRef).map((item) => item.model);
              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.PARTS_LIST_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
          pageSize={productsPerPage}
          rowsPerPageOptions={[productsPerPage]}
          onPageChange={(_evt, details) => {
            onStateChange(details.api.state);
          }}
          onSortModelChange={(_evt, details) => {
            onStateChange(details.api.state);
          }}
          onRowClick={(params, evt, details) => {
            // evt.preventDefault();
            // evt.defaultMuiPrevented = true;
            setSelectedRowId((state) => {
              if (state === params.id) {
                return null;
              } else {
                return params.id;
              }
            });
            onStateChange(details.api.state);
          }}
          toolbar={{
            options: {
              columns: false,
              filters: true,
              density: false,
              export: false,
            },
          }}
        />
        </Grid>
        {carrouselDataSource[0]
          ? (
         <Carousel
          NextIcon={<NavigateNext />}
          PrevIcon={<NavigateBefore />}
          autoPlay={false}
          className={classes.carousel}
          index={0}
          fullHeightHover={false}
          selectedItemId={selectedRowId}
          indicators={false}
          navButtonsAlwaysInvisible={user.marketPlaceInventoryCarouselView}
          navButtonsAlwaysVisible={!parts.loading}
          swipe={false}
          NavButton={({ onClick, style, next, prev }) => {
            return (
                <IconButton disableRipple onClick={onClick} className={next ? 'carousel-next' : 'carousel-prev'} style={style}>
                    {next && <NavigateNext sx={{ fontSize: '50px' }} color='#000' />}
                    {prev && <NavigateBefore sx={{ fontSize: '50px' }} color='#000' />}
                </IconButton>
            );
          }}
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              borderRadius: '50%',
              padding: '15px',
              color: '#000',
              fontSize: '50px'
            }
          }}
          navButtonsWrapperProps={{
            style: {
              marginLeft: user.marketPlaceInventoryCarouselView ? '-100px' : 0
            }
          }}
          sx={{
            maxHeight: '561px',
            overflow: user.marketPlaceInventoryCarouselView ? 'unset' : 'hidden',
            marginTop: user.marketPlaceInventoryCarouselView ? theme.spacing(4) : theme.spacing(2),
            '& .carousel-prev': {
              left: user.marketPlaceInventoryCarouselView ? 'unset' : '-30px',
              // right: user.marketPlaceInventoryCarouselView ? '1230px' : 'unset',
              opacity: viewApiRef?.current?.state?.pagination?.page === 0 ? 0.1 : 1,
              pointerEvents: viewApiRef?.current?.state?.pagination?.page === 0 ? 'none' : 'all',
              position: user.marketPlaceInventoryCarouselView ? 'fixed' : 'inherit'
            },
            '& .carousel-next': {
              right: user.marketPlaceInventoryCarouselView ? 'unset' : '-30px',
              opacity: viewApiRef?.current?.state?.pagination?.page === (viewApiRef?.current?.state?.pagination?.pageCount - 1) ? 0.1 : 1,
              pointerEvents: viewApiRef?.current?.state?.pagination?.page === (viewApiRef?.current?.state?.pagination?.pageCount - 1) ? 'none' : 'all',
              position: user.marketPlaceInventoryCarouselView ? 'fixed' : 'inherit'
            },
          }}
          next={() => {
            viewApiRef.current.setPage(viewApiRef.current.state.pagination.page + 1);
            setTimeout(() => {
              goToTop();
            }, 200);
          }}
          prev={() => {
            viewApiRef.current.setPage(viewApiRef.current.state.pagination.page - 1);
            setTimeout(() => {
              goToTop();
            }, 200);
          }}
        >
          {carrouselDataSource.map((item) => (
            <CarouselItem
              key={'carouselIndex'}
              items={item}
              selectedItemId={selectedRowId}
              loadingMoreProducts={loadingMoreProducts}
              handleClassificationChange={handleClassificationChange}
            />
          ))}
        </Carousel>
            ) : parts.loading
              ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10%' }}>
        <CircularProgress />
      </Box>
              : <Grid item xs={12} textAlign="center" marginTop='10%'>
        <Typography variant="h6" fontWeight="bolder">No results found</Typography>
      </Grid>}
      </Grid>
     <Grid item xs={12} visibility={user.marketPlaceInventoryCarouselView ? 'hidden' : 'none'}>
        <Datagrid
          loading={parts.loading}
          data={parts.data}
          columns={
            columns(renderCell, user.type)
          }
          // onCellEditStop={(params, event) => {
          //   updatePrice(params, event);
          // }}
          onCellEditCommit={(params) => {
            if (params.field === 'Price') {
              updatePrice(params);
            }
            if (params.field === 'costCode') {
              handleUpdateCostCode(params);
            }
          }}
          setGridFriltersSubset={setCarrouselDataSource}
          apiRef={apiRef}
          height={'auto'}
          onFilterModelChange={(_model, details) => {
            onStateChange(details.api.state);
            setTimeout(() => {
              const filterData = gridFilteredSortedRowEntriesSelector(apiRef).map((item) => item.model);
              if (filterData) {
                dispatch({
                  type: REDUX_ACTIONS.PARTS_LIST_FILTER,
                  payload: {
                    data: filterData,
                  }
                });
              }
            }, 300);
          }}
          onPageChange={(_evt, details) => {
            onStateChange(details.api.state);
          }}
          onSortModelChange={(_evt, details) => {
            onStateChange(details.api.state);
          }}
          onRowClick={(params, evt, details) => {
            // evt.preventDefault();
            // evt.defaultMuiPrevented = true;
            setSelectedRowId((state) => {
              if (state === params.id) {
                return null;
              } else {
                return params.id;
              }
            });
            onStateChange(details.api.state);
          }}
          pageSize={3}
          rowsPerPageOptions={[3]}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: false,
              export: false,
            },
          }}
          columnBuffer={4}
        />
      </Grid>
      {user.marketPlaceInventoryCarouselView ? <ScrollToTopButton /> : null}
    </Grid>
  );
};

Part.propTypes = {
  partIDs: PropTypes.arrayOf(PropTypes.string),
};

Part.defaultProps = {
  partIDs: [],
};

export default Part;
