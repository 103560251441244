import * as ENVIRONMENT from '../shared/config/config';
import store from '../store/store';
import axios from 'axios';

const http = {
  METHODS: {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    DELETE: 'delete',
  },
};

const apiRequestTimeout = 1200000; // 20 minutes

const BASE_URL = ENVIRONMENT.API_URL;

const post = (url, body, headers = {}) => {
  const { currentUser } = store.getState();
  const ipDetails = {
    ip: currentUser?.ip,
  };
  let newBody = body;
  if (currentUser) {
    newBody = {
      ...newBody,
      user: {
        type: currentUser.type,
        companyId: currentUser.companyId,
        userId: currentUser.userId,
        ...(ipDetails?.ip && { ...ipDetails })
      },
    };
  }

  const options = {
    url: `${BASE_URL}${url}`,
    method: http.METHODS.POST,
    headers: {
      'Content-type': 'application/json',
      ...headers,
    },
    data: JSON.stringify(newBody),
    withCredentials: true,
    timeout: apiRequestTimeout,
  };

  return axios(options).then(async (res) => {
    const resultData = (res.data) ? JSON.stringify((res.data)) : null;
    res = new Response(resultData, { status: res.status });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const res = new Response(JSON.stringify(error.response.data), { status: error.response.status, statusText: error.response.statusText });
    if (location.pathname !== '/Login' && res.status === 401) {
      localStorage.setItem('user', null);
      localStorage.clear();
      return (location = '/Login');
    } else if (location.pathname === '/Login') {
      const response = await res.json();
      return Promise.reject(response.message);
    }
    return Promise.reject(res);
  });
};

const put = (url, body, headers = {}) => {
  let newBody = body;
  const { currentUser } = store.getState();
  const ipDetails = {
    ip: currentUser?.ip,
  };
  if (currentUser) {
    newBody = {
      ...newBody,
      user: {
        type: currentUser.type,
        companyId: currentUser.companyId,
        userId: currentUser.userId,
        ...(ipDetails?.ip && { ...ipDetails })
      },
    };
  }
  const options = {
    url: `${BASE_URL}${url}`,
    method: http.METHODS.PUT,
    headers: {
      'Content-type': 'application/json',
      ...headers,
    },
    data: JSON.stringify(newBody),
    withCredentials: true,
    timeout: apiRequestTimeout,
  };

  return axios(options).then(async (res) => {
    const resultData = (res.data) ? JSON.stringify((res.data)) : null;
    res = new Response(resultData, { status: res.status });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const res = new Response(JSON.stringify(error.response.data), { status: error.response.status, statusText: error.response.statusText });
    if (location.pathname !== '/Login' && res.status === 401) {
      localStorage.setItem('user', null);
      localStorage.clear();
      return (location = '/Login');
    } else if (location.pathname === '/Login') {
      const response = await res.json();
      return Promise.reject(response.message);
    }
    return Promise.reject(res);
  });
};

const deleteAction = (url, body, headers = {}) => {
  const { currentUser } = store.getState();
  let newBody = body;
  const ipDetails = {
    ip: currentUser?.ip,
  };
  if (currentUser) {
    newBody = {
      ...newBody,
      user: {
        type: currentUser.type,
        companyId: currentUser.companyId,
        userId: currentUser.userId,
        ...(ipDetails?.ip && { ...ipDetails })
      },
    };
  }
  const options = {
    url: `${BASE_URL}${url}`,
    method: http.METHODS.DELETE,
    headers: {
      'Content-type': 'application/json',
      ...headers,
    },
    data: JSON.stringify(newBody),
    withCredentials: true,
    timeout: apiRequestTimeout,
  };

  return axios(options).then(async (res) => {
    const resultData = (res.data) ? JSON.stringify((res.data)) : null;
    res = new Response(resultData, { status: res.status });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const res = new Response(JSON.stringify(error.response.data), { status: error.response.status, statusText: error.response.statusText });
    if (location.pathname !== '/Login' && res.status === 401) {
      localStorage.setItem('user', null);
      localStorage.clear();
      return (location = '/Login');
    } else if (location.pathname === '/Login') {
      const response = await res.json();
      return Promise.reject(response.message);
    }
    return Promise.reject(res);
  });
};

const downloadPost = (url, body, headers = {}) => {
  const { currentUser } = store.getState();
  let newBody = body;
  if (currentUser) {
    newBody = {
      ...newBody,
      user: {
        type: currentUser.type,
        companyId: currentUser.companyId,
        userId: currentUser.userId,
      },
    };
  }

  const options = {
    url: `${BASE_URL}${url}`,
    method: http.METHODS.POST,
    headers: {
      'Content-type': 'application/json',
      ...headers,
    },
    data: JSON.stringify(newBody),
    withCredentials: true,
    timeout: apiRequestTimeout,
    responseType: 'arraybuffer'
  };

  return axios(options).then(async (res) => {
    res = new Response(res.data, { status: res.status, headers: res.headers });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const responseData = new TextDecoder().decode(error.response.data);
    const res = new Response(responseData, { status: error.response.status, statusText: error.response.statusText });
    if (location.pathname !== '/Login' && res.status === 401) {
      localStorage.setItem('user', null);
      localStorage.clear();
      return (location = '/Login');
    } else if (location.pathname === '/Login') {
      const response = await res.json();
      return Promise.reject(response.message);
    }
    return Promise.reject(res);
  });
};

const neutralGet = (url, headers = {}, timeout = apiRequestTimeout) => {
  const options = {
    url,
    method: http.METHODS.GET,
    headers,
    timeout,
  };

  return axios(options).then(async (res) => {
    const resultData = (res.data) ? JSON.stringify((res.data)) : null;
    res = new Response(resultData, { status: res.status });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const res = new Response(JSON.stringify(error?.response?.data || error?.message || ''), { status: error?.response?.status || 408, statusText: error?.response?.statusText || error?.code });
    return Promise.reject(res);
  });
};

const get = (url, headers = {}) => {
  const options = {
    url: `${BASE_URL}${url}`,
    method: http.METHODS.GET,
    headers: {
      'Content-type': 'application/json',
      ...headers,
    },
    withCredentials: true,
    timeout: apiRequestTimeout,
  };

  return axios(options).then(async (res) => {
    const resultData = (res.data) ? JSON.stringify((res.data)) : null;
    res = new Response(resultData, { status: res.status });
    return Promise.resolve(res);
  }).catch(async (error) => {
    const res = new Response(JSON.stringify(error.response.data), { status: error.response.status, statusText: error.response.statusText });
    return Promise.reject(res);
  });
};

export default {
  post,
  put,
  deleteAction,
  downloadPost,
  neutralGet,
  get
};
