/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton } from '@mui/material';
import { CancelPresentationRounded, ContentCopyOutlined, DeleteRounded, DirectionsRunRounded, EditOutlined, SaveRounded, SendRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import columns from './table_config';

import styles from './styles';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import ReactJson from 'react-json-view';
import { addNewHydraulicsRow, copyHydraulicsRow, createHydraulics, deleteHydraulics, listHydraulics, listHydraulicsDetails, runHydraulics, updateHydraulics } from '../../../store/actions/hydraulics';
import HydraulicsDetailsModal from './HydraulicsRunModalDialog';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import SelectEditInputTextField from '../SelectEditInputTextField';
import PumpRampModel from './PumpRampModel';
import DepthVsDensityChartDialog from './DepthVsDensityChartDialog';
import DeleteConfirmationModal from '../../deleteConfirmationModal';
const HydraulicsModal = ({
  onCloseClick,
  selectedJob,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const apiRef = useGridApiRef();
  const hydraulics = useSelector((state) => state.hydraulics);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [showPumpRamp, setShowPumpRamp] = useState(false);
  const [pumpRamps, setPumpRamps] = useState([]);
  const [showDepthVsDensity, setShowDepthVsDensity] = useState({
    open: false,
    data: []
  });
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();

  useEffect(() => {
    if (isOpen && (selectedJob?._id || selectedJob?.wellsInfoId)) {
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(listHydraulics(query));
    }
  }, [isOpen]);

  useEffect(() => {
    if (hydraulics?.data) {
      setProperties(hydraulics?.data);
    }
  }, [hydraulics?.data]);

  useEffect(() => {
    if (properties[0]?.name === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'name', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {selectedJob?.Rig || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                setProperties([]);
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const addHydraulics = () => {
    dispatch(addNewHydraulicsRow());
  };

  const handleSave = (row) => {
    if (row?.name) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      if (row?.isNewRow) {
        delete row?.isNewRow;
        dispatch(createHydraulics(row, selectedJob?._id, selectedJob.wellsInfoId, false, selectedJob?.fromWells));
      } else {
        dispatch(updateHydraulics({ ...row }, selectedJob?.fromWells));
      }
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'Please enter the name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const removeHydraulics = (row) => {
    if (row?.isNewRow) {
      const updatedHydraulics = properties.filter(item => item._id !== row?._id);
      setProperties(updatedHydraulics);
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(listHydraulics(query));
    } else {
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(deleteHydraulics(row?._id, query));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const runHydraulicsProxy = (hydraulicsParams) => {
    const body = {
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          },
      runId: hydraulicsParams?._id
    };
    dispatch(runHydraulics(body));
  };

  const RenderJsonField = ({ value }) => {
    const data = useRef(value);
    return (
      <ReactJson
        src={data.current}
        name={false}
        sortKeys={true}
        collapsed={true}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
    );
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || !row?.name || row?.isNewRow}
          onClick={() => {
            if (!row?.isNewRow) {
              dispatch(listHydraulicsDetails(row?._id));
            } else {
              delete row?.isNewRow;
              dispatch(createHydraulics(row, selectedJob?._id, selectedJob.wellsInfoId, true, selectedJob?.fromWells));
              dispatch({
                type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
                payload: {
                  data: [],
                  paginatedElements: 0,
                },
              });
            }
            setEditData(row);
            setEditDialog(true);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading}
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            dispatch(copyHydraulicsRow(row));
          }}
        >
          <ContentCopyOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || row?.isNewRow || !row?.run_details || Object.keys(row?.run_details).length === 0}
          onClick={() => {
            runHydraulicsProxy(row);
          }}
        >
          <DirectionsRunRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || row?.isNewRow}
          onClick={() => {
          }}
        >
          <SendRounded />
        </IconButton>
      </Grid>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };
  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  if (apiRef.current === null) apiRef.current = {};

  return (
    <>
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={() => {
        setProperties([]);
        onCloseClick();
      }}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' onClick={addHydraulics} className={hydraulics.loading || properties[0]?.isNewRow ? classes.disableIcon : ''}>+ Add</Typography>
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              },
              '& .cellLink': {
                color: '#0000EE',
                cursor: 'pointer'
              }
            }}
            loading={hydraulics.loading}
            columns={columns(RenderJsonField, renderActionCell, renderValueCell)}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            onCellClick={(row) => {
              if (row.field === 'connection_sbp' && row?.row?.run_response?.pump_ramps?.length) {
                setShowPumpRamp(true);
                setPumpRamps(row);
              }
              if (row.field === 'bh_esd' || row.field === 'bh_ecd') {
                if (row.row?.run_response?.depth_points.length) {
                  setShowDepthVsDensity({
                    open: true,
                    data: row.row
                  });
                }
              }
            }}
            disableStickyHeader={false}
            autoHeight={false}
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            height={'50vh'}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            pageSize={100}
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
    {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={removeHydraulics}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
      {editDialog && editData?.name
        ? (
        <HydraulicsDetailsModal
        selectedJob={selectedJob}
          isOpen={editDialog}
          onCloseClick={() => {
            setEditDialog(false);
            setEditData({});
            dispatch({
              type: REDUX_ACTIONS.HYDRAULICS_SET_ID,
              payload: {
                data: '',
              },
            });
          }}
          data={editData}
        />
          )
        : null}
        {
          showPumpRamp
            ? <PumpRampModel
            pumpRamps={pumpRamps}
            isOpen={showPumpRamp}
            onCloseClick={() => {
              setShowPumpRamp(false);
            }}
          />
            : null
        }
        {showDepthVsDensity.open && (
          <DepthVsDensityChartDialog
           isOpen={showDepthVsDensity.open}
           onCloseClick={() => {
             setShowDepthVsDensity({
               open: false,
               data: []
             });
           }}
           data={showDepthVsDensity.data}
          />
        )}
    </>
  );
};
HydraulicsModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
HydraulicsModal.defaultProps = {
  selectedJob: {}
};
export default HydraulicsModal;
