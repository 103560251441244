import { ACCESS_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import fetch from '../../api/fetch';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import store from '../store';
import * as ENVIRONMENT from '../../shared/config/config';
import axios from 'axios';

export const setAccessType = (accessTypeDetails) => (dispatch) => {
  let accessType = ACCESS_TYPES.NORMAL;
  switch (parseInt(accessTypeDetails.accessType, 10)) {
    case 0:
      accessType = ACCESS_TYPES.NORMAL;
      break;
    case 1:
      accessType = ACCESS_TYPES.NEW_USER;
      break;
    case 2:
      accessType = ACCESS_TYPES.FORGOTTEN_PASSWORD;
      break;
    default:
      break;
  }
  dispatch({
    type: REDUX_ACTIONS.ACCESS_TYPE,
    payload: {
      accessType,
      newUserId: accessTypeDetails.newUserId,
      newPasswordProvided: false,
    },
  });
};

export const loginUser = (user) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.LOGIN_USER_LOADING,
  });
  const body = {
    email: user.emailAddress,
    password: btoa(user.password),
  };
  return fetch
    .post('users/user-login', body)
    .then((resp) => resp.text())
    .then((data) => {
      const parsedData = JSON.parse(data);
      if (parsedData.statusCode === 200) {
        dispatch({
          type: REDUX_ACTIONS.LOGIN_USER_SUCCESS,
        });
        dispatch({
          type: REDUX_ACTIONS.SET_USER,
          payload: { ...parsedData.data },
        });
        dispatch(getUserPreferences('resetAspect'));
        localStorage.setItem('user', JSON.stringify({ ...parsedData.data }));
        return parsedData.data;
      } else if (parsedData.statusCode === 400) {
        dispatch({
          type: REDUX_ACTIONS.LOGIN_USER_FAILED,
          payload: { message: 'User not authorized' },
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.LOGIN_USER_FAILED,
          payload: { message: 'Invalid username or password' },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: REDUX_ACTIONS.LOGIN_USER_FAILED,
        payload: { message: error },
      });
      return { error: true, message: error.message };
    });
};

export const logoutUser = () => (dispatch) => {
  // TODO: moved the session to cookies or a persistent object implementing jwt security aproach
  localStorage.setItem('user', null);
  localStorage.clear();

  dispatch({
    type: REDUX_ACTIONS.REMOVE_USER,
  });
  dispatch({
    type: REDUX_ACTIONS.PARTS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.ORDERS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.JOBS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.COMPANIES_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.USERS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.ASSET_DATA_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.QUOTES_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.ASSETS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_CUSTOMERS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_PARTS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.ALARMS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.DATA_CHART_PREFERENCES_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_PRICE_LISTS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_SALE_ORDERS_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_WORK_FORCE_RESET,
  });
  dispatch({
    type: REDUX_ACTIONS.EPICOR_BINS_RESET,
  });
};

export const sendPasswordResetEmail = (emailAddress) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_LOADING,
  });

  const body = {
    email: emailAddress,
  };

  return fetch
    .post('users/user-password-reset-email', body)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch({
          type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_FAILED,
          payload: { message: 'Failed to send password reset email.' },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_FAILED,
        payload: { message: 'Failed to send password reset email.' },
      });
      return { error: true, message: error.message };
    });
};

export const sendPasswordResetEmailSuccess = () => ({
  type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
});

export const sendPasswordResetEmailFailure = (message) => ({
  type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_FAILED,
  payload: { message },
});

export const sendPasswordResetEmailReset = () => ({
  type: REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_RESET,
});

export const resetPassword = (userId, key, newPassword) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.RESET_PASSWORD_LOADING,
  });

  const body = {
    userId,
    newPassword: btoa(newPassword),
  };

  const headers = {
    Authorization: `Bearer ${key}`,
  };

  return fetch
    .post('users/user-password-recovery', body, headers)
    .then((resp) => {
      if (resp.status === 204) {
        dispatch({
          type: REDUX_ACTIONS.RESET_PASSWORD_SUCCESS,
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.RESET_PASSWORD_FAILED,
          payload: { message: 'Failed to reset password.' },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: REDUX_ACTIONS.RESET_PASSWORD_FAILED,
        payload: { message: 'Failed to reset password.' },
      });
      return { error: true, message: error.message };
    });
};

export const resetPasswordSuccess = () => ({
  type: REDUX_ACTIONS.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (message) => ({
  type: REDUX_ACTIONS.RESET_PASSWORD_FAILED,
  payload: { message },
});

export const resetPasswordReset = () => ({
  type: REDUX_ACTIONS.RESET_PASSWORD_RESET,
});

export const sendSignUpRequest = (emailAddress) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_LOADING,
  });

  const body = {
    email: emailAddress,
  };

  return fetch
    .post('users/sign-up', body)
    .then((resp) => {
      if (resp.status === 200) {
        dispatch({
          type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_SUCCESS,
        });
      } else {
        dispatch({
          type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_FAILED,
          payload: { message: 'Failed to send sign up request.' },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_FAILED,
        payload: { message: 'Failed to send sign up request.' },
      });
      return { error: true, message: error.message };
    });
};

export const sendSignUpRequestSuccess = () => ({
  type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_SUCCESS,
});

export const sendSignUpRequestFailure = (message) => ({
  type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_FAILED,
  payload: { message },
});

export const sendSignUpRequestReset = () => ({
  type: REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_RESET,
});

export const getUserPreferences = (resetAspect) => async (dispatch) => {
  return await fetch
    .post('users/preferences/get')
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      if (data?.values) {
        localStorage.setItem('shoppingCarts', JSON.stringify({ ...data.values.details.shoppingCarts }));
        dispatch({
          type: REDUX_ACTIONS.GET_UPDATE_USER_PREFERENCES,
          payload: data.values.details,
          resetAspect
        });
      }
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateUserPreferences = () => async (dispatch) => {
  const { currentUser } = store.getState();
  const preferences = currentUser.preferences;

  return await fetch
    .put('users/preferences', { preferences })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const deletePreferences = (closeDialog) => (dispatch) => {
  const body = {
    collectionNames: ['Preferences', 'ChartPreferences'],
  };
  return fetch
    .post('users/preferenceReset', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Preferences Cleared Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GREEN_LIST,
        payload: [],
      });
      dispatch({
        type: REDUX_ACTIONS.ORDERS_SET_GRAY_LIST,
        payload: [],
      });
      localStorage.removeItem('shoppingCarts');
      dispatch(getUserPreferences());
      closeDialog();
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const uploadProfilePicture = (file) => async (dispatch) => {
  const formData = new FormData();
  const { currentUser } = store.getState();
  const BASE_URL = ENVIRONMENT.API_URL;
  formData.append('file', file);
  const payload = {
    user: {
      type: currentUser.type,
      companyId: currentUser.companyId,
      userId: currentUser.userId,
    },
  };
  formData.append('payload', JSON.stringify(payload));
  try {
    const response = await axios.put(`${BASE_URL}users/profilePic`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.statusText !== 'OK') {
      return Promise.reject(response);
    }
    dispatch(
      enqueueSnackbar(
        'Profile Picture Uploaded Successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );
    const responseData = response.data;
    const getUserItem = JSON.parse(localStorage.getItem('user'));
    getUserItem.profilePic = responseData.profilePic;
    const updateGetUserItem = JSON.stringify(getUserItem);
    localStorage.setItem('user', updateGetUserItem);
    dispatch({
      type: REDUX_ACTIONS.USERS_PROFILE_PICTURE_UPLOAD_SUCCESS,
      payload: {
        message: true,
        profilePic: responseData.profilePic
      },
    });
  } catch (error) {
    const errorMessage = await parseError(error);
    dispatch(
      enqueueSnackbar(
        errorMessage,
        'error',
        new Date().getTime() + Math.random()
      )
    );
    dispatch({
      type: REDUX_ACTIONS.USERS_PROFILE_PICTURE_UPLOAD_ERROR,
      payload: {
        message: false
      },
    });
  }
};
