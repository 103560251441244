/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, Box, FormControl, InputLabel, FilledInput, Select, MenuItem } from '@mui/material';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import Datagrid from '../../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../../ui/Datagrid/table_config';
import ModalDialog from '../../ui/ModalDialog';
import { createDrillStringBitProperty, deleteDrillBitDetailsProperty, updateDrillStringBitDetailsProperty, updateDrillStringDetails } from '../../../store/actions/wellsDrillString';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { WELLS_DRILL_STRING_BIT_TYPE } from '../../../utils/constants';
import SelectEditInputTextField from '../../ui/SelectEditInputTextField';
import DeleteConfirmationModal from '../../deleteConfirmationModal';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const DrillStringBitDetails = ({
  handleClose,
  data,
  open,
  drillStringData
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const apiRef = useGridApiRef();
  const drillString = useSelector((state) => state.wellsDrillString);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [bitSize, setBitSize] = useState('');
  const [bitType, setBitType] = useState('');
  const [bitTfa, setBitTfa] = useState('');
  const [disableAdd, setDisableAdd] = useState(false);
  const [bitSizeError, setBitSizeError] = useState(false);
  const [disableTfaField, setDisableTfaField] = useState(false);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteNozzleAction, setDeleteNozzleAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});

  useEffect(() => {
    if (!drillString.bit_details.loading) {
      const setBitNozzles = drillString?.bit_details?.data?.nozzles?.map((item, index) => ({
        number: index + 1,
        size: item,
        _id: generateRandom(),
      }));
      setProperties(setBitNozzles || []);
      setBitType(drillString?.bit_details?.data?.type || '');
      setBitTfa(drillString?.bit_details?.data?.tfa || '');
      setBitSize(drillString?.bit_details?.data?.size || '');

      if (!drillString?.bit_details?.data?.nozzles?.length && drillString?.bit_details?.data?.tfa) {
        setBitTfa(drillString?.bit_details?.data?.tfa);
        setDisableAdd(true);
      }
    }
    return () => {
      setProperties([]);
    };
  }, [drillString?.bit_details]);

  useEffect(() => {
    if (properties?.length && properties[0]?.size === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'size', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'size');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties?.length - 1;
    const newRow = {
      number: currentProperties?.length + 1,
      size: '',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true
    };
    currentProperties.unshift(newRow);
    const updatedProperties = currentProperties?.map((item, index) => ({
      ...item,
      number: currentProperties?.length - index
    }));
    setProperties(updatedProperties);
  };

  useEffect(() => {
    if (properties?.length) {
      const totalSize = properties.reduce((sum, property) => sum + Math.pow(property?.size || 0, 2), 0);
      const tfa = totalSize / 1303.8;
      setBitTfa(tfa.toFixed(3));
      setDisableTfaField(true);
    }
    if (properties.length === 0 && drillString?.bit_details?.data?.nozzles?.length !== 0) {
      setBitTfa('');
      setDisableTfaField(false);
    }
  }, [properties]);

  const handleDeleteBit = () => {
    dispatch(deleteDrillBitDetailsProperty(data?._id ? data?._id : drillString.drillStringId, data.wellsInfoId));
    const updatedData = {
      ...data,
      _id: drillString?.drillStringId ? drillString?.drillStringId : data?._id,
      bit_details: []
    };
    dispatch(updateDrillStringDetails(updatedData));
    setDisableTfaField(false);
    setDisableAdd(false);
    setDeleteAction(false);
  };

  const checkBitProperties = () => {
    const invalidFields = [];
    if (bitType === '') invalidFields.push('Type');
    if (bitSize === '') invalidFields.push('Size');
    if (bitSize !== '' && (Number(bitSize) < 2.5 || Number(bitSize) >= 50)) invalidFields.push('Bit size must be between 2.5 and 50 inches');
    if (bitTfa === '' || Number(bitTfa) === 0) invalidFields.push('TFA');
    const nozzles = properties.filter(item => item.size !== '').map(item => item.size);
    for (const nozzle of nozzles) {
      const numericNozzle = Number(nozzle);
      if (numericNozzle <= 0 || numericNozzle > 50 || !Number.isInteger(numericNozzle)) {
        invalidFields.push('Nozzle value should be an integer between 0 and 50');
        break;
      }
    }
    if (invalidFields.length === 0) {
      return true;
    } else {
      dispatch(
        enqueueSnackbar(
            `Please check the following details: ${invalidFields.join(', ')}`,
            'error',
            new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };
  const saveProperty = () => {
    if (checkBitProperties()) {
      const nozzles = properties.filter(item => item.size !== '')?.map(item => item?.size).reverse();
      const body = {
        type: bitType,
        size: Number(bitSize),
        tfa: Number(bitTfa),
        nozzles
      };
      if (drillString?.bit_details?.data?._id) {
        dispatch(updateDrillStringBitDetailsProperty(data?._id ? data?._id : drillString.drillStringId, body, data.wellsInfoId));
      } else {
        dispatch(createDrillStringBitProperty(data?._id ? data?._id : drillString.drillStringId, body, data.wellsInfoId));
      }
      const updatedData = {
        ...data,
        _id: drillString?.drillStringId ? drillString?.drillStringId : data?._id,
        bit_details: body
      };
      dispatch(updateDrillStringDetails(updatedData));
    }
  };

  const updateData = (id, field, value) => {
    const numericValue = Number(value);
    if (!Number.isInteger(numericValue) || numericValue <= 0 || numericValue > 50) {
      setInvalidRows(prev => ({ ...prev, [id]: true }));

      let errorMessage = '';
      if (numericValue <= 0 || numericValue > 50) {
        errorMessage = 'Nozzle value should be between 0 and 50';
      }
      if (!Number.isInteger(numericValue)) {
        errorMessage = 'Nozzle value should be an integer';
      }
      if ((numericValue <= 0 || numericValue > 50) && !Number.isInteger(numericValue)) {
        errorMessage = 'Nozzle value should be an integer between 0 and 50';
      }

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    } else {
      setInvalidRows(prev => ({ ...prev, [id]: false }));
    }
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value === '' ? '' : numericValue
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const handleDeleteNozzles = (row) => {
    const filteredProperties = properties.filter((item) => item._id !== row._id);
    const updatedProperties = filteredProperties.map((item, index) => ({
      ...item,
      number: filteredProperties.length - index
    }));
    setProperties(updatedProperties);
    setDeleteNozzleAction(false);
    setDeleteRow({});
  };

  const getModalTitle = () => {
    return (
            <>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>{drillStringData?.row?.name}, {data.MD} {'(ft)'}</Grid>
                    <Grid item>
                        <IconButton
                            onClick={handleClose}
                        >
                            <CancelPresentationRounded fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
            </>
    );
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderActions = ({ row }) => {
    return (
            <Grid container justifyContent="center">
                <IconButton
                    disabled={drillString.details.loading}
                    variant="solid"
                    onClick={() => {
                      setDeleteNozzleAction(true);
                      setDeleteRow(row);
                    }}
                >
                    <DeleteRounded />
                </IconButton>
            </Grid>
    );
  };

  const handleBitSize = (value) => {
    const numericValue = Number(value);
    if (value === '' || (numericValue >= 2.5 && numericValue <= 50)) {
      setBitSize(value);
      setBitSizeError(false);
    } else {
      setBitSize(value);
      setBitSizeError(true);
      dispatch(
        enqueueSnackbar(
          'Bit size must be between 2.5 and 50 inches',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    }
  };

  return (
        <ModalDialog
            title={getModalTitle()}
            isOpen={open}
            onClose={handleClose}
            maxWidth='lg'
            dialogClass={classes.dialogModal}
        >
            <Grid container item>
                <Grid container item marginLeft='12px' marginTop='15px' marginBottom='5px'>
                    <Grid marginBottom='5px'>
                    <Typography variant="h5">Bit</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={5}>
                        <Grid item xs={3}>
                            <FormControl
                                variant="filled"
                                className={classes.inputField}
                                sx={{ bgcolor: 'transparent' }}
                            >
                                <InputLabel id={'signal-label'}>Select Bit Type</InputLabel>
                                <Select
                                    labelId={'signal-select'}
                                    id={'signal-select'}
                                    value={bitType}
                                    onChange={(e) => setBitType(e.target.value)}
                                    disableUnderline
                                >
                                    {WELLS_DRILL_STRING_BIT_TYPE?.map((item, idItem) => (
                                        <MenuItem key={`item_${item?.name}_${idItem}`} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
                            <FormControl
                                fullWidth
                                variant="filled"
                                className={classes.inputField}
                                sx={{ bgcolor: 'transparent' }}
                            >
                                <InputLabel fontWeight='bolder' fontSize='20px' htmlFor="outlined-adornment-select">
                                    Enter Bit Size
                                </InputLabel>
                                <FilledInput
                                    id="outlined-adornment-select"
                                    value={bitSize}
                                    disableUnderline
                                    sx={{
                                      border: bitSizeError ? '5px solid #ffcccc' : ''
                                    }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*\.?\d*$/.test(value)) {
                                        handleBitSize(value);
                                      }
                                    }}
                                />
                            </FormControl>
                            <Typography fontSize='12px' fontWeight='900' marginLeft='2%'>inches</Typography>
                        </Grid>
                        <Grid item xs={3} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
                            <FormControl
                                fullWidth
                                variant="filled"
                                className={classes.inputField}
                                sx={{ bgcolor: 'transparent' }}
                            >
                                <InputLabel fontWeight='bolder' fontSize='20px' htmlFor="outlined-adornment-filter">
                                    TFA
                                </InputLabel>
                                <FilledInput
                                    id="outlined-adornment-filter"
                                    value={bitTfa}
                                    disableUnderline
                                    disabled={disableTfaField}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (!isNaN(value) && value !== ' ') {
                                        setBitTfa(value);
                                        if (value === '' || Number(value) === 0) {
                                          setDisableAdd(false);
                                        } else {
                                          setDisableAdd(true);
                                        }
                                      }
                                    }}
                                />
                            </FormControl>
                            <Typography fontSize='12px' fontWeight='900' marginLeft='2%'>inches²</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton
                                variant="solid"
                                disabled={drillString.bit_details.loading}
                                fontSize="large"
                                onClick={() => {
                                  saveProperty();
                                }}
                            >
                                <SaveRounded />
                            </IconButton>
                            <IconButton
                                disabled={drillString.bit_details.loading}
                                variant="solid"
                                onClick={() => {
                                  setDeleteAction(true);
                                }}
                            >
                                <DeleteRounded />
                            </IconButton>
                        </Grid>

                    </Grid>
                    <Grid item className={classes.customTool} xs={1} justifyContent="flex-start" marginTop='15px'>
                        <Box>
                            <Typography variant="h5">Nozzles</Typography>
                            <Typography variant='button' marginTop='5px' fontWeight='900' className={disableAdd || properties?.length > 23 ? classes.disableIcon : ''} onClick={handleAddProperty}>+ Add</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Datagrid
                        apiRef={apiRef}
                        data={properties?.length ? properties : []}
                        loading={drillString.bit_details.loading}
                        getRowId={(row) => row._id}
                        columns={columns(renderCell, renderActions, renderValueCell)}
                        disableStickyHeader={false}
                        autoHeight={false}
                        height={'50vh'}
                        sx={{
                          marginLeft: '-6px',
                          '.MuiDataGrid-cell': {
                            fontSize: '1rem',
                            fontWeight: 900
                          },
                          '.MuiDataGrid-columnHeader': {
                            fontSize: '1.2rem',
                            fontWeight: '900 !important'
                          }
                        }}
                        pageSize={100}
                        editMode="cell"
                        toolbar={{
                          options: {
                            columns: false,
                            filters: false,
                            density: false,
                            export: false
                          },
                        }}
                        onCellEditCommit={({ id, value, field }) => {
                          updateData(id, field, value);
                        }}
                        getRowClassName={(params) => {
                          return invalidRows[params.id] ? classes.invalidRow : '';
                        }}
                    />
                </Grid>
        {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={handleDeleteBit}
            handleClose={setDeleteAction}
          />
        }
        {
          deleteNozzleAction && <DeleteConfirmationModal
            open={deleteNozzleAction}
            handleDelete={handleDeleteNozzles}
            handleClose={setDeleteNozzleAction}
            deleteRow={deleteRow}
          />
        }
            </Grid>
        </ModalDialog>
  );
};
DrillStringBitDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  drillStringData: PropTypes.any
};
DrillStringBitDetails.defaultProps = {
  data: {}
};
export default DrillStringBitDetails;
