const columns = (renderValueCell, renderActions) => [
  {
    field: 'hole_depth',
    headerName: 'Hole Depth (ft)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'bit_depth',
    headerName: 'Bit Depth (ft)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rop',
    headerName: 'ROP (ft/hr)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'flow_in',
    headerName: 'Flow Rate (gpm)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    headerName: 'SBP (psi)',
    width: 250,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 350,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
