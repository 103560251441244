const columns = (renderCell, RenderJsonField, renderActions, renderValueCell) => [
  {
    field: 'MD',
    headerName: 'MD (ft)',
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'details',
    headerName: 'Details',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      const filteredData = data?.value?.length
        ? data.value.map((item) => ({
          mw: item.mw,
          fann_600: item.fann_600,
          fann_300: item.fann_300,
          fann_200: item.fann_200,
          fann_100: item.fann_100,
          fann_6: item.fann_6,
          fann_3: item.fann_3,
          gel_10min: item.gel_10min,
          gel_10sec: item.gel_10sec,
          tank_vol: item.tank_vol,
          pv: (item.fann_600 - item.fann_300).toFixed(2),
          yp: (item.fann_300 - (item.fann_600 - item.fann_300)).toFixed(2)
        }))
        : {};

      return <RenderJsonField value={filteredData} />;
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
