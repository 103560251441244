import { ListAltRounded, ViewCarouselRounded } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';

const InventoryView = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const handleInventory = (value) => {
    let inventoryViewPayload = false;
    if (value) {
      inventoryViewPayload = value;
    }
    dispatch({
      type: REDUX_ACTIONS.SET_MARKETPLACE_INVENTORY_VIEW,
      payload: { marketPlaceInventoryCarouselView: inventoryViewPayload },
    });
  };

  return (
    <>
      <Box sx={{ marginLeft: 'auto', marginRight: '-7px' }}>
        <InputAdornment position="start">
          <IconButton
            edge="end"
            onClick={() => {
              handleInventory(!user.marketPlaceInventoryCarouselView);
            }}
          >
            {user.marketPlaceInventoryCarouselView ? <ListAltRounded /> : <ViewCarouselRounded /> }
          </IconButton>
        </InputAdornment>
      </Box>
    </>
  );
};

export default InventoryView;
