import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import { listWells } from './wells';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const listDrillFluid = (wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: wellId
    }
  };
  return fetch
    .post('wells/entity/drillFluid/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewDrillFluidRow = () => (dispatch) => {
  const { wellsDrillFluid } = store.getState();
  const newDrillFluid = [...wellsDrillFluid.data];
  const nextId = newDrillFluid.length - 1;
  const newRow = {
    MD: '',
    details: [],
    lastUpdate: new Date(),
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newDrillFluid.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_SUCCESS,
    payload: {
      data: newDrillFluid,
      paginatedElements: newDrillFluid.length,
    },
  });
};

export const createDrillFluid = (body, wellId, details) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_LOADING,
  });
  delete body._id;
  const input = {
    input: {
      ...body,
      wellsInfoId: wellId
    }
  };
  return fetch
    .post('wells/entity/drillFluid/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      if (details) {
        dispatch({
          type: REDUX_ACTIONS.WELLS_DRILL_FLUID_SET_ID,
          payload: {
            data: data.data._id,
          },
        });
      }
      dispatch(
        enqueueSnackbar(
          'Drill Fluid Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(wellId));
    });
};
export const updateDrillFluid = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_LOADING,
  });
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      ...body
    }
  };
  return fetch
    .put('wells/entity/drillFluid', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Fluid Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(body?.wellsInfoId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(body?.wellsInfoId));
    });
};
export const deleteDrillFluidRow = (deleteDrillFluidId, wellId, wellInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_LOADING,
  });
  const body = {
    query: {
      _id: deleteDrillFluidId,
      wellsInfoId: wellInfoId
    }
  };
  return fetch
    .deleteAction('wells/entity/drillFluid', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill fluid deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(wellId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluid(wellId));
    });
};
export const updateDrillFluidDetails = (data) => (dispatch) => {
  const { wellsDrillFluid } = store.getState();
  const currentDrillFluid = [...wellsDrillFluid.data];
  const index = currentDrillFluid.findIndex(drillstring => drillstring._id === data._id);
  currentDrillFluid[index] = data;
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_SUCCESS,
    payload: {
      data: currentDrillFluid,
      paginatedElements: currentDrillFluid.length,
    },
  });
};
export const importDrillFluidDetails = (data) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_LOADING,
  });
  const drillFluidList = data.sort((a, b) => a.md - b.md).map((value, index) => {
    return {
      ...value,
      id: generateRandom(),
      grid_id: index,
      _id: index
    };
  });
  setTimeout(() => {
    dispatch({
      type: REDUX_ACTIONS.WELLS_DRILL_FLUID_LIST_SUCCESS,
      payload: {
        data: drillFluidList,
        paginatedElements: drillFluidList.length,
      }
    });
    dispatch(
      enqueueSnackbar(
        'CSV file imported Successfully.',
        'success',
        new Date().getTime() + Math.random()
      ));
  }, 2000);
};

export const listDrillFluidDetails = (drillId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_LOADING,
  });
  const body = {
    query: {
      drillId
    }
  };
  return fetch
    .post('wells/entity/drillFluid/properties/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const createDrillFluidDetailsProperty = (drillId, body, wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_LOADING,
  });
  delete body._id;
  const input = {
    query: {
      drillId,
      wellsInfoId
    },
    input: body
  };
  return fetch
    .post('wells/entity/drillFluid/properties/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Fluid Details Property Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluidDetails(drillId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluidDetails(drillId));
    });
};

export const updateDrillFluidDetailsProperty = (drillId, _id, body, wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_LOADING,
  });
  const input = {
    query: {
      drillId,
      wellsInfoId,
      _id
    },
    input: body
  };
  return fetch
    .put('wells/entity/drillFluid/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Fluid Details Property  Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluidDetails(drillId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluidDetails(drillId));
    });
};

export const deleteDrillFluidDetailsProperty = (drillId, _id, wellsInfoId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_LOADING,
  });
  const input = {
    query: {
      drillId,
      wellsInfoId,
      _id
    },
  };
  return fetch
    .deleteAction('wells/entity/drillFluid/properties', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Drill Fluid Details Property  Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listDrillFluidDetails(drillId));
      dispatch(listWells());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
    });
};
